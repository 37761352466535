import { Request } from 'bernie-http';
import { checkValue } from 'src/components/functions';

export const sanityUrls = [
  {
    campaignPartner: 'see',
    campaignTemplate: 'visitUSA',
    BatchQueue: 'busa',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'magazine',
    BatchQueue: 'magazine',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'best-time-to-book-en-us',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'best-time-to-book-en-gb',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'best-time-to-book-en-au',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'meilleurs-moments-pour-reserver',
    BatchQueue: 'btb',
  },
  {
    campaignPartner: 'see',
    campaignTemplate: 'beste-zeit-zu-buchen',
    BatchQueue: 'btb',
  },
];

export const routeMapper = (partner, templateName, feedName, query: Request['query']) => {
  const { dummyData, batchQuery, filter, sanity } = query || {};

  const isByPassSanity = sanityUrls.filter((u) => u?.campaignTemplate?.toLowerCase() === templateName.toLowerCase());

  const isSanityData = (checkValue(templateName) && checkValue(isByPassSanity, true)) || sanity === '1';
  const isSanityDummyData = isSanityData && dummyData === '1';
  const finalBatchQuery =
    checkValue(templateName) && checkValue(isByPassSanity, true)
      ? isByPassSanity?.[0]?.BatchQueue
      : checkValue(batchQuery)
      ? batchQuery
      : 'b1';
  const finalBusaFilter = filter ?? '';

  return {
    isSanityData,
    isSanityDummyData,
    batchQuery: finalBatchQuery,
    busaFilter: finalBusaFilter,
  };
};
