import { isEmpty } from 'lodash';
import { checkValue } from 'src/components/common/helper';

export const updateAppDownloadModulePosition = (array, appModule = false, campaign) => {
  // Filter out objects with type "appDownload"
  const filteredArray = array.filter((item) => item.module !== 'appDownload');

  // Find the first object with type "appDownload"
  // const firstAppObject = array.find(item => item.module === "appDownload");

  //get the predefined app module
  let module = checkValue(campaign?.appDownload) ? { ...campaign?.appDownload, module: 'v3appDownload' } : {};

  // If an "appDownload" object exists, add it to the end of the filtered array
  if (module && !isEmpty(module)) {
    filteredArray.push(module);
  }

  return appModule === true ? filteredArray : array;
};
