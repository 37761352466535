import { AllNavigation, AllAllStudioTemplate } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import { sanityBrandMap } from '../helpers/sanityBrandMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import { updateAppDownloadModulePosition } from '../helpers/updateAppDownloadModulePosition';
import setHeroModule from '../helpers/setHeroModule';

export class AllStudioTemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();
  public async fetchAllStudioServiceTemplate(
    partner: string,
    templateName: string,
    isDummyData: boolean,
    pagelang: string,
  ): Promise<any> {
    const variables: AllAllStudioTemplate.Variables = {
      where: {
        pageUrl: {
          current: {
            eq: templateName,
          },
        },
        locale: {
          eq: sanityLocaleMap(pagelang),
        },
      },
    };

    const data = await this.sanityApiSource.allStudioTemplateRequest(variables, partner);

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: [...data.data.allAllStudioTemplate, ...sanityDummyData.content],
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(data) && checkValue(data.data) && checkValue(data.data.allAllStudioTemplate)) {
        const allStudioItem = data?.data?.allAllStudioTemplate?.[0];
        const campaign = allStudioItem?.pos;
        const showMarkerIo = allStudioItem?.showMarkerIo;
        const { modules, ...rest } = allStudioItem;

        const formattedModules = checkValue(modules, true)
          ? allStudioItem?.modules?.map((module: any) => ({
              module: module?._type,
              ...module,
              dataFeed: FeedType.Sanity,
            }))
          : [];

        const heroModule = setHeroModule(allStudioItem?.heroChoice, allStudioItem);

        const moduleListWithAppModuleUpdates = updateAppDownloadModulePosition(formattedModules , allStudioItem?.tags?.appModule , campaign)

        const finalFormattedModules = moduleListWithAppModuleUpdates?.map((module: any) => {
            if(module._type === "breakerQuote") {
               return {
                ...module,
                author: module?.authorString
               }
            }else {
                return module
            }
        })

        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          showMarkerIo,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
