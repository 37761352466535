import { PageTheme, FeedType } from 'src/components/common/module-util';
import { checkValue } from 'src/components/common/helper';

const heroPropName = {
  articleHero: 'articleHeroSettings',
  liveEventHero: 'liveEventHeroSettings',
  videoHero: 'videoHeroSettings',
  sponsoredContentHero: 'sponsorHeroSettings',
};

const setHeroModule = (heroChoise = 'articleHero', data) => {
  const heroname = checkValue(heroPropName[heroChoise]) ? heroPropName[heroChoise] : 'articleHero';
  const hero = checkValue(data?.[heroname]) ? data?.[heroname] : null;

  const heroTags = [{ prefLabel: 'Beach' }, { prefLabel: 'Nature' }];

  const hideHero = checkValue(hero?.hideHero) ? hero?.hideHero : false;

  const heroModule =
    checkValue(hero) && !hideHero
      ? {
          module: hero._type,
          dataFeed: FeedType.Sanity,
          moduleType: 'hero',
          ...hero,
          tags: heroTags,
        }
      : null;

  return heroModule;
};

export default setHeroModule;
